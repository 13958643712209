@import "../../styles/mixins";

.discord-social-container {
    text-decoration: none;
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: 2px solid #5865F2;
    border-radius: 12px;
    background-color: #5865f21b;

    .header {
        margin-top: 10px;
        margin-bottom: 0;

        transition: margin-top 0.1s ease-in;
    }

    transition: background-color 0.1s ease-in;

    >img {
        width: 125px;
    }

    &.disabled {
        filter: grayscale(1.0);
    }
}

.discord-social-container:hover {
    background-color: #5865f23a;

    .header {
        margin-top: 12px;
    }
}

.color-discord {
    color: #5865F2
}