@import "../styles/mixins";

#licenseDetailsCard {
    width: 100%;
    height: 100%;

    > div.header {
        margin: 0px;
        padding: 16px;
        background-color: #ffffff0a;
        border-radius: 8px 8px 0 0;

        p {
            margin: 0;
        }
    }

    > div.content {
        background-color: #1a1d20;
        border-radius: 0 0 8px 8px;
        height: 100%;
        overflow-y: auto;
    }

    .card {
        .header {
            display: flex;
            flex-direction: row;
            padding: 14px;

            > img {
                margin-right: 16px;
                height: 22px;
                width: 22px;
            }
        }
    }
}

.card {
    p {
        margin: 0;
    }
}

#licenseActivationSummaryCard {
    border-top: 1px solid #0f1114;
    padding-bottom: 24px;
}

#licActivationsSummaryContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .item {
        display: flex;
        flex-direction: column;
        border-right: 1px solid gray;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
        align-self: center;
    }

    .item:last-child {
        border-right: none;
    }

    @include below-screen-large {
        grid-template-columns: repeat(2, 1fr);
    }
}

#licActivationsCard {
    border-top: 1px solid #0f1114;
}

#licActivationsContent {
    display: flex;
    flex-direction: column;

    .activation-row {
        &:hover {
            background-color: lighten($color: #1a1d20, $amount: 2);
        }

        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 24px;

        p,
        label {
            vertical-align: middle;
        }

        div {
            align-self: center;
        }

        div:nth-child(1) {
            img {
                width: 22px;
                height: 22px;
                margin-right: 10px;
                vertical-align: middle;
            }
        }

        div:nth-child(2) {
            text-align: center;
        }

        div:nth-child(3) {
            display: flex;
            justify-content: right;
        }
    }
}
