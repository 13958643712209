.note {
    background-color: #1a1d20;
    border: none;
    border-radius: 8px;
    border: 1px solid #ffffff0f;

    font-size: 0.8125rem;

    padding-right: 12px;
    padding-left: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
}