@import "../styles/variables";

.dropdown {
    .header {
        display: flex;
        flex-direction: row;
        cursor: pointer;

        * {
            pointer-events: none;
        }
    }

    .header {
        .toggle:last-child {
            margin-left: 15px;
            margin-right: 10px;
        }

        button.toggle {
            padding: 0;
            background: none;
            border: none;

            > img {
                width: 100%;
                height: 10px;
            }
        }
    }

    .content.hidden {
        visibility: hidden;
    }

    .content {
        position: absolute;
        max-height: 40vh;
        border: solid 1px #3a3c3f80;
        box-shadow: 0px 9px 37px 0px #00000059;
        border-radius: 8px;
        background: #111417;
        overflow-x: hidden;
        overflow-y: auto;
        margin-top: 5px;
        z-index: 1000;

        .item {
            padding: 10px 15px 10px 15px;
            width: 100%;
        }

        .item:hover {
            background-color: #ffffff1a;
            cursor: pointer;
        }

        .item.disabled:hover {
            cursor: default;
        }

        .item.disabled {
            color: #ffffff;
            opacity: 0.5;
        }
    }
}

.dropdown .user-summary {
    display: flex;
    flex-direction: row;

    > img {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        object-fit: contain;
    }

    > div {
        margin-left: 15px;

        p {
            margin: 0;
        }

        > p:nth-child(1) {
        }

        > p:nth-child(2) {
            opacity: 50%;
            font-size: 0.9em;
        }
    }
}
