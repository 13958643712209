@import "../styles/mixins";

form {
    .input-group {
        margin-top: 8px;
        margin-bottom: 8px;

        display: flex;
        flex-direction: column;

        > label,
        > div {
            margin-bottom: 5px;
        }

        &.inline {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            
            select,
            input,
            .input,
            .file-upload {
                width: 100%;
                max-width: 400px;
            }

            @include below-screen-large {
                input,
                .file-upload {
                    min-width: initial;
                    width: 100%;
                }

                flex-direction: column;
                align-items: initial;

                & > label,
                & > div {
                    vertical-align: middle;
                }
            }
        }
    }

    label {
        color: rgba($color: white, $alpha: 0.8);
    }

    p.danger,
    label.danger,
    span.danger,
    small.danger {
        font-size: 0.85rem;
        font-weight: lighter;
    }

    p.danger {
        margin: 3px;
    }

    button[type="submit"] {
        @include below-screen-large {
            width: 100%;
        }
    }
}
