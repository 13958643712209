@import "../styles/mixins";

.timeline {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 9px;
        height: 44px;

        > div {
            display: flex;
            flex-direction: row;
            padding: calc(14px - 9px);

            > img {
                width: 22px;
                height: 22px;
                margin-right: 16px;
                object-fit: contain;
            }

            > p {
                margin: 0;
            }
        }

        .year-picker {
            display: flex;
            flex-direction: row;
            gap: 8px;

            button {
                padding: 5px 10px !important;
            }

            div {
                padding: 4px 10px;
                border-radius: 8px;
                border: #ffffff29 2px solid;
            }

            div:nth-child(2) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .content {
        canvas {
            width: 100%;
        }
    }

    #swSelectorContainer {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .selector {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 2px solid #ffffff29;
        border-radius: 25px;
        padding: 3px 10px;
        cursor: pointer;
        user-select: none;

        p {
            margin: 0;

            @include below-screen-small {
                display: none;
            }
        }

        > img {
            height: 22px;
            width: 22px;
        }

        &.selected {
            border-color: rgb(3, 135, 232);
            background-color: rgb(3, 135, 232, 0.2);
        }

        &:hover {
            // background-color: red;
        }
    }
}
