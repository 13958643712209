.tab {
    border-radius: 8px;
    padding: 10px 16px;
    text-align: center;
    cursor: pointer;
    overflow: hidden;

    &.selected {
        background-color: #0387E84D;
    }
}