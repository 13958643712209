@import "../styles/_variables.scss";
@import "../styles/mixins";

.sidebar {
    --sidebar-margin-left: 20px;

    z-index: 10;
    position: sticky;
    top: 0;

    background-color: $jfx-bg-primary;

    min-width: 260px;
    height: 100vh;
}

.sidebar .logo {
    height: 48px;

    margin-bottom: 15px;
    margin-top: 15px;
    margin-left: var(--sidebar-margin-left);
}

@include below-screen-large {
    @keyframes slide-in {
        from {
            left: -260px;
        }

        to {
            left: 0px;
        }
    }

    @keyframes slide-out {
        from {
            left: 0px;
        }

        to {
            left: -260px;
        }
    }

    .sidebar {
        height: 100%;
    }

    .sidebar.hidden {
        position: fixed;
        left: -260px;

        animation: slide-out 0.3s ease-out;
    }

    .sidebar.visible {
        display: block;
        position: fixed;
        left: 0px;

        animation: slide-in 0.3s ease-in;
    }
}
