#securityPage {
    padding: 20px;
}

#changePasswordForm {
    max-width: 768px;
}

#mfaEnableForm {
    display: flex;
    flex-direction: row;
    max-width: 400px;
    gap: 24px;

    &:nth-child(1) {
        svg {
            width: 100%;
            align-self: center;
        }
    }

    :nth-child(2) {
        input {
            width: 100%;
        }
    }
}
