select {
    background-color: #1a1d20;
    border: none;
    border-radius: 8px;
    border: 1px solid #ffffff0f;

    font-size: 13px;

    padding-right: 12px;
    padding-left: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
}

select:disabled {
    opacity: 0.7;
}

input.error {
    border: 1px solid hsla(349, 69%, 63%, 1);
}

select:hover {
    border-color: rgba($color: $jfx-primary-hover, $alpha: 0.4);
}

select:active,
select:focus-visible {
    outline: none;
    border-color: $jfx-primary;
    background-color: #0a0c0d;
}
