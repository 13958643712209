@import "../styles/variables";

.accordion {
    display: flex;
    flex-direction: column;

    color: white;

    &.expanded {
        background-color: #111417;
    }

    .content {
        display: flex;
        flex-direction: column;
    }

    .content.hidden {
        height: 0;
        overflow: hidden;
    }

    .header .title {
        flex-grow: 1;
        align-self: center;
    }

    .header {
        display: flex;
        height: $sidebar-item-height;

        &:hover {
            cursor: pointer;
            background-color: hsl(210deg 15% 14%);
        }

        > button {
            width: 100%;
            text-align: left;
            pointer-events: none;

            background-color: transparent;
            border: none;
        }

        .icon {
            display: flex;
            justify-content: center;
            width: $sidebar-icon-container-width;

            > img {
                align-self: center;
                width: 16px;
                height: 16px;
            }
        }

        .toggle-icon {
            display: flex;
            justify-content: right;
            padding-right: 7px;
            
            img {
                align-self: center;
                width: 16px;
                height: 16px;
            }
        }
    }

    nav a {
        display: flex;
        align-items: center;

        height: $sidebar-item-height;
        padding-left: $sidebar-icon-container-width;

        &,
        &:link,
        &:visited {
            color: white;
            text-decoration: none;
        }

        &:hover {
            background-color: #182834;
        }

        &:active {
            background-color: rgba($color: $jfx-primary, $alpha: 0.2);
        }

        &.active {
            background-color: #182834;
            border-left: $jfx-primary 2px solid;
        }
    }
}

/*
NAV LINKS
*/
