@import "../styles/variables";

input {
    background-color: #1a1d20;
    border: none;
    border-radius: 8px;
    border: 1px solid #ffffff0f;

    font-size: 13px;

    padding-right: 12px;
    padding-left: 12px;
    padding-top: 11px;
    padding-bottom: 11px;
}

input:disabled {
    opacity: 0.7;
}

input.error {
    border: 1px solid hsla(349, 69%, 63%, 1);
}

input:hover {
    border-color: rgba($color: $jfx-primary-hover, $alpha: 0.4);
}

input:active,
input:focus-visible {
    outline: none;
    border-color: $jfx-primary;
    background-color: #0a0c0d;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

div.file-upload > input {
    visibility: collapse;
    display: none;
}

div.file-upload {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    padding: 15px 0px 15px 0px;

    border: 1px dashed rgba($color: $jfx-primary-pressed, $alpha: 0.6);

    p {
        margin: 0;
    }

    .highlight {
        color: $jfx-primary;
    }

    &:hover:not(.disabled) {
        &:not(.error) {
            background-color: #0387e81a;
            border: 1px dashed rgba($color: $jfx-primary, $alpha: 0.4);

            .highlight {
                color: $jfx-primary;
            }
        }
    }

    &.fileHovering:not(.disabled) {
        $hover-green: #219653;

        background-color: #2196531a;
        border: 1px dashed $hover-green;

        .highlight {
            color: $hover-green;
        }
    }

    &.error {
        $error-color: #e25f77;
        border: 1px dashed rgba($color: $error-color, $alpha: 1);
        background-color: rgba($color: $error-color, $alpha: 0.1);

        .highlight {
            color: #e25f77;
        }

        &:hover {
            background-color: rgba($color: $error-color, $alpha: 0.2);
        }
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.7;

        border: 1px dashed rgba($color: $jfx-primary-pressed, $alpha: 0.3);
    }
}

div.file-upload * {
    pointer-events: none;
}
