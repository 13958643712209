@import "../styles/variables";

#authenticatorPage {
    
}

.authenticator-container {
    display: flex;

    margin-left: auto;
    margin-right: auto;

    min-height: 100vh;
    max-width: 400px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authenticator-content {
    background: $jfx-bg-primary;
    border-radius: 0px 0px 8px 8px;
    box-sizing: border-box;
    width: 100%;
    padding: 25px;
}

nav.authenticator-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    width: 100%;

    a {
        text-decoration: none;
        background-color: #131516;
        width: 100%;
        text-align: center;
        padding: 10px;
        border-radius: 8px 8px 0px 0px;

        &.active {
            background-color: $jfx-bg-primary;
        }
    }
}
