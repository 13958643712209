@import "./variables";

hr {
    border: 1px solid #222528b2;
}

a.mignon {
    text-decoration: none;
}

.mignon {
    color: $jfx-primary;
    font-size: 15px;

    &:hover {
        text-decoration: underline;
    }
}

.text-center {
    text-align: center;
}

hr {
    width: 100%;
    
    &.tiny {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    &.normal {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &.big {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}