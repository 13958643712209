@import "../../../styles/mixins";

#marketplacePurchasesPage {
    margin: 20px;
}

#productsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    grid-template-rows: auto;
    gap: 15px;

    @include below-screen-tiny {
        grid-template-columns: 1fr;
    }
}

.product-card {
    position: relative;
    display: flex;
    flex-direction: column;

    border: 1px solid transparent; // placeholder
    background-color: #1a1d20;
    border-radius: 8px;
    overflow: hidden;

    .ownedbanner {
        position: absolute;
        width: 100%;
        z-index: 10;
    }

    .header {
        height: 200px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform linear 0.2s;
        }
    }

    &:hover {
        .header img {
            transform: scale(1.05);
        }
    }

    &.owned {
        border: 1px solid #219653;
    
        p {
            margin: 0;
            padding: 3px 0px;
            font-size: 11px;
            text-align: center;
            background-color: #219653;
            font-weight: bold;
        }
    }
}