@import "mixins";

dialog {
    background-color: #16191c;
    border-radius: 8px;
    border: none;
    max-width: 1200px;

    &::backdrop {
        background: rgba(0, 0, 0, 0.8);
    }

    > div.header {
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        font-size: 1.4rem;
        font-weight: 500;
        padding-bottom: 8px;
        margin-bottom: 8px;
    }

    > div.content {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}
