.monthlytoannualupgrade-card {
    
}

.progressindicator {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10px;

    > div {
        width: 100%;
        border-right: 1px solid black;
        background-color: #474A4D;

        &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }

        &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    > div.reached {
        background-color: #56C867;
    }
}