@import "../../../styles/mixins";

#licensesDetailedListContainer {
    display: grid;
    grid-template-columns: 250px 200px auto;
    grid-template-rows: 36px minmax(auto, 500px);
    grid-template-areas:
        "searchbar filters ."
        "licensesList licensesList licenseDetails";
    gap: 20px;

    @include below-screen-large {
        grid-template-columns: 250px auto;
        grid-template-rows: 36px auto auto;
        grid-template-areas:
            "searchbar filters"
            "licensesList licensesList"
            "licenseDetails licenseDetails";
    }
}

#licensesList {
    thead {
        border-bottom: 1px solid #0f1114;
    }

    .item {
        td:nth-child(1) {
            display: flex;
            flex-direction: row;
            gap: 8px;
        }
    }
}

p.key {
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
}