@import "../styles/variables";

.link {
    display: flex;
    flex-direction: row;
    height: $sidebar-item-height;

    &:is(a) {
        text-decoration: none;
    }

    &.active {
        background-color: #182834;
        border-left: $jfx-primary 2px solid;
    }

    .icon {
        display: flex;
        width: $sidebar-icon-container-width;
        justify-content: center;

        > img {
            align-self: center;
            width: 16px;
            height: 16px;
        }
    }

    .content {
        flex-grow: 1;
        align-self: center;
    }

    &:hover {
        cursor: pointer;
        background-color: hsl(210deg 15% 14%);
    }
}
