.list {
    display: flex;
    flex-direction: column;
}

.list-item {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    margin-bottom: 6px;

    > img {
        align-self: flex-start;
        margin-right: 10px;
    }
}
