.tab-group {
    display: flex;
    flex-direction: row;

    background-color: #15181B;
    border-radius: 8px;

    .tab,
    a {
        text-decoration: none;
        flex-grow: 1;
    }
}