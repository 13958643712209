.disclaimer {
    border-radius: 15px;
    padding: 5px 10px;
    font-weight: 300;
    font-size: 14px;
}

.disclaimer.danger {
    border: 1px solid #e25f77;
    background-color: #e25f7721;
}

.disclaimer.warning {
    border: 1px solid #ffbb38;
    background-color: #ffbb3821;
}

.disclaimer.success {
    border: 1px solid #219653;
    background-color: #21965321;
}
