@import "../../../styles/mixins";

#personalPage {
    padding: 20px;
}

#accountInformationForm {
    max-width: 768px;
}

#socialContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    width: auto;

    @include below-screen-tiny {
        display: grid;
        gap: 10px;
        grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);
    }
}
