@import "../../styles/mixins";

.jfx-licenseCard {
    margin-top: 10px;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: 20% 50% 10% auto;
    flex-direction: row;
    
    gap: 15px;
    padding: 20px 40px 20px 40px;

    background-color: hsla(210, 10%, 11%, 1);
    box-shadow: 0px 1px 2px 0px hsla(220, 43%, 11%, 0.06);
    border-radius: 8px;

    @include below-screen-xlarge {
        grid-template-columns: 50% 50%;
    }

    @include below-screen-small {
        grid-template-columns: 100%;
    }
}

.jfx-licenseCard > img {
    object-fit: contain;
}

.jfx-licenseCard label {
    color: hsla(112, 100%, 100%, 0.5);
}

.jfx-licenseCard > img ~ div {
    align-self: center;
}
