@import "../styles/variables";
@import "../styles/mixins";

#alertsContainer {
    position: absolute;
    right: 0px;
    padding: 10px;
    top: calc(#{$top-bar-height});

    @include below-screen-large {
        width: 100%;
    }
}

.toast {
    @keyframes fade-in {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    display: grid;
    grid-template-columns: 50px auto 24px;
    margin-bottom: 16px;
    background-color: #1a1d20;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #2a2e32;
    box-shadow: 0px 10px 46px 5px rgba(0, 0, 0, 0.3);

    animation: fade-in 0.3s ease-in;

    width: 400px;

    .title {
        align-self: center;
        color: #d1d2d2;
    }

    .content {
        $expandedMaxHeight: 300px;

        @keyframes expand {
            from {
                max-height: 0px;
            }

            to {
                max-height: $expandedMaxHeight;
            }
        }

        @keyframes shrink {
            from {
                max-height: $expandedMaxHeight;
            }

            to {
                max-height: 0px;
            }
        }

        grid-column-start: 2;
        opacity: 0.5;
        font-family: "Hando";
        font-weight: lighter;
        max-height: 0px;
        overflow-y: hidden;

        &.show {
            animation: expand 0.2s ease-in;
            max-height: $expandedMaxHeight;
        }

        &.hide {
            animation: shrink 0.2s ease-out;
            max-height: 0px;
        }
    }

    .close {
        cursor: pointer;
        margin-left: auto;
        margin-right: 0;
    }

    @include below-screen-large {
        width: 100%;
    }
}
