@import "../styles/variables";

.icon {
    $pad-right: &;

    height: 100%;

    > img {
        width: 100%;
        height: 100%;
    }

    .bubble {
        position: relative;

        border: 3px solid $jfx-bg-primary;
        top: calc(-100% - 20px);
        left: 50%;
        padding: 0px 5px 0px 5px;

        font-size: 0.8rem;

        background-color: $jfx-primary;
        text-align: center;
        border-radius: 9.5px;

        :hover {
            background-color: $jfx-primary-hover;
        }
    }
}
