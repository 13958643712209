.tooltip {
    cursor: pointer;

    .content {
        display: none;
        position: absolute;
        background-color: #0f1114;
        border-radius: 12px;
        border: 1px solid #1e2228;
        filter: drop-shadow(0 0 10px black);
        offset-anchor: left bottom;

        p {
            margin: 0;
        }

        .pad {
            padding: 12px 12px;
        }
    }

    &:hover {
        .content {
            display: block;
        }
    }
}
