#mainLayout {
    display: flex;

    flex-direction: row;
}

@keyframes fade-in {
    from {
        backdrop-filter: blur(0px);
    }

    to {
        background-filter: blur(5px);
    }
}

#fullPageDimmer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    transition: backdrop-filter 0.5s;

    &.hide {
        height: 0px;
    }

    &.show {
        height: 100%;
        backdrop-filter: blur(5px) opacity(1);
    }
}

#topbar {
    z-index: 1000;
}

#page {
    flex-grow: 1;
}

#content {
    flex-grow: 1;
}
