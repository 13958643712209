@import "./styles/variables";

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("/assets/fonts/Hando-Regular.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/Hando-ExtraLight.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/Hando-Thin.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/assets/fonts/Hando-Light.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/Hando-Medium.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/Hando-Bold.otf")
}

@font-face {
  font-family: "Hando";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Hando-ExtraBold.otf")
}

body {
  background-color: $jfx-bg-secondary;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

*::selection {
  color: white;
  background-color: $jfx-primary-pressed;
}

#devHeader {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #e25f77;
  user-select: none;
  z-index: 2000;

  &:hover {
    opacity: 0.2;
  }
}
