$jfx-primary: #0387e8;
$jfx-primary-hover: #1ea2ff;
$jfx-primary-pressed: #0069ca;
$jfx-primary-locked: #0387e8;

$jfx-secondary: #ffffff0f;
$jfx-secondary-hover: #ffffff17;
$jfx-secondary-pressed: #ffffff0a;
$jfx-secondary-locked: #ffffff0f;

$jfx-negative: #e25f771a;
$jfx-negative-hover: #e25f7733;
$jfx-negative-pressed: #e25f770d;

$jfx-blackfriday: rgb(251, 136, 1);

$jfx-bg-primary: #1a1d20;
$jfx-bg-secondary: #111417;

$top-bar-height: 69px;

/*
 * Sidebar
 */

$sidebar-item-height: 44px;
$sidebar-icon-container-width: 56px;
