@import "../../styles/variables";

.admin-user-card {
    background-color: #1a1d20;
    border-radius: 8px;
    padding: 20px;

    .avatar {
        border-radius: 50%;
    }

    div.no-avatar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        user-select: none;
        width: 75px;
        height: 75px;
        background-color: #0387e8;
        border-radius: 50%;
        font-weight: bolder;
        font-size: 200%;
    }
}

.subscription-detail-card {
    // border: 1px solid rgb(198, 198, 198);
    border: 1px dashed rgba($color: #0387e8, $alpha: 0.6);
    background-color: rgba(255, 255, 255, 0.054);
    border-radius: 25px;
    padding: 15px 15px;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    h7 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .product {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
    }

    .title {
        display: flex;
        flex-direction: row;
    }

    .content {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
}

.licenseCard {
    padding: 5px 10px;
    border-radius: 25px;

    &:hover {
        background: lighten($color: $jfx-bg-primary, $amount: 0.1);
    }
}
