@import "../../styles/mixins";

.subscription-card {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 24px;
    max-width: 330px;
    border: 1px solid transparent; // Placeholder to prevent shifting on hover

    background-color: #1a1d20;
    .logo {
        width: 150px;
    }

    .subheader {
        font-size: 0.8rem;
        margin: 0;
        opacity: 40%;
    }

    .price {
        font-family: "Hando";
        font-weight: 600;

        font-size: 2rem;
        margin-top: 10px;
        margin-bottom: 0;

        > span {
            font-weight: normal;
            font-size: 1rem;
            color: #777778;
        }
    }

    .list-item {
        color: white;
        opacity: 65%;

        > img {
            align-self: center;
        }
    }

    .header {
        display: grid;
        grid-template-columns: 50px auto 20px;
        grid-template-rows: auto auto;
        column-gap: 10px;

        .logo {
            grid-row: 1 / span 2;
            grid-column: 1 / 1;
            width: 100%;
            height: 50px;
        }

        .title {
            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                margin: 0;
            }

            grid-row: 1 / 1;
            grid-column: 2 / 2;
        }

        .subtitle {
            p {
                margin: 0;
            }
            font-size: 0.9rem;

            grid-row: 2 / 2;
            grid-column: 2 / 2;
        }

        .extra {
            grid-row: 1 / 2;
            grid-column: 3;
        }
    }
}

.subscription-card:hover {
    border: 1px solid lighten($color: #1a1d20, $amount: 10%);
}

@include below-screen-tiny {
    .subscription-card {
        max-width: initial;
        width: 100%;
    }
}
