table {
    width: 100%;
    border-collapse: collapse;

    a {
        text-decoration: none;
    }

    thead {
        background-color: #ffffff0a;
        border-radius: 8px 8px 0 0;

        td {
            padding: 15px 22px 15px 22px;
        }
    }

    tr {
        border-bottom: 1px solid #0f1114;

        &:last-child {
            border-bottom: none;
        }
    }

    thead,
    tbody,
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    &.small tbody {
        td {
            padding: 14px 12px;
        }
    }

    td.center {
        text-align: center;
        justify-content: center;
    }

    tr.disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    tbody {
        display: block;
        overflow: overlay;
        border-radius: 0 0 8px 8px;

        td {
            padding: 24px 22px 24px 22px;
        }

        tr {
            background-color: #1a1d20;
            cursor: pointer;

            &:hover {
                background-color: lighten($color: #1a1d20, $amount: 2);
            }

            &.selected {
                background-color: #0387e84d;
            }
        }

        // Prevent the scrollbar to go over the rounded area thus letting it overlfow
        &::-webkit-scrollbar-track {
            margin-bottom: 8px;
        }
    }
}
