@import "./variables";

@import "./maps";
@import "./text";
@import "./inputs";
@import "./misc";
@import "./margins";
@import "./form";
@import "./list";
@import "./grid";
@import "./dialog";
@import "./select";
@import "./note";
@import "./table";
@import "./disclaimer";
@import "./tooltip";

*,
small {
    color: white;
    font-family: "Hando";
}

body {
    background-color: #0f1114;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background-color: transparentize($color: #D9D9D9, $amount: 0.9);
    border-radius: 32px;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 400;
}

a img {
    vertical-align: middle;
}