/*
    MISC
*/

.margin-0 {
    margin: 0;
}

/*
    FLEX
*/

.d-flex {
    display: flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-gap-small {
    gap: 10px;
}

.flex-gap-medium {
    gap: 10px;
}

.flex-gap-large {
    gap: 20px;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.justify-content-space-evenly {
    justify-content: space-evenly;
}

.justify-content-space-around {
    justify-content: space-around;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}
