@import "../../styles/mixins";

#homePage {
    margin: 20px;
}

#downloadCardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 330px);
    gap: 15px;
}

.card-download {
    display: flex;
    flex-direction: column;
    align-items: start;
    background: #1a1d20;
    border-radius: 8px;
    padding: 24px;
    gap: 16px;
    box-shadow: 0px 1px 2px 0px #1018280f;
    box-shadow: 0px 1px 3px 0px #1018281a;

    .header h3 {
        font-size: 1.3rem;
        font-weight: bold;
        margin: 0;
    }

    .logo {
        position: relative;
        height: 35px;
        object-fit: contain;
    }

    .description {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;

        &:hover {
            display: block;
        }
    }

    .btn {
        border-radius: 8px !important;
    }

    .btn * {
        vertical-align: middle;
    }

    .dropdown:first-child {
        padding: 11px 14px;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.06);
        background: rgba(255, 255, 255, 0.04);

        div:first-child {
            flex-grow: 1;
        }
    }

    .dropdown .header {
        label {
            text-wrap: nowrap;
        }
        .toggle {
            margin: 0;
        }
    }

    dialog.releasenotes {
        background-color: #1a1d20;
        border-color: #2c3034;
        border-radius: 16px;
        max-width: 50%;

        &::backdrop {
            background-color: #191a1bad;
        }

        .header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
        }

        > div {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        @include below-screen-small {
            max-width: 95%;
            max-height: 90%;
        }
    }
}
