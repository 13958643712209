@import "../styles/mixins";

.modal {
    &.hidden {
        display: none;
    }

    position: fixed;
    z-index: 1000;
    backdrop-filter: blur(5px) brightness(0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bg-catcher {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .container {
        @keyframes slide-in {
            from {
                filter: opacity(0.2);
                top: 15%;
            }

            to {
                top: 20%;
                filter: opacity(1);
            }
        }

        position: relative;
        display: flex;
        flex-direction: column;
        top: 20%;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        margin-right: auto;
        animation: 0.1s linear slide-in;

        background-color: #16191c;
        border-radius: 8px;

        &.tiny {
            width: 25%;

            @include below-screen-xxlarge {
                width: 25%;
            }

            @include below-screen-xlarge {
                width: 40%;
            }

            @include below-screen-large {
                width: 50%;
            }

            @include below-screen-small {
                width: 70%;
            }

            @include below-screen-tiny {
                width: 95%;
            }
        }

        &.small {
            width: 30%;

            @include below-screen-xxlarge {
                width: 35%;
            }

            @include below-screen-xlarge {
                width: 50%;
            }

            @include below-screen-large {
                width: 50%;
            }

            @include below-screen-small {
                width: 60%;
            }

            @include below-screen-tiny {
                width: 95%;
            }
        }

        &.medium {
            width: 50%;

            @include below-screen-xxlarge {
                width: 50%;
            }

            @include below-screen-xlarge {
                width: 60%;
            }

            @include below-screen-large {
                width: 60%;
            }

            @include below-screen-small {
                width: 75%;
            }

            @include below-screen-tiny {
                width: 95%;
            }
        }

        &.large {
            width: 70%;

            @include below-screen-xxlarge {
                width: 60%;
            }

            @include below-screen-xlarge {
                width: 60%;
            }

            @include below-screen-large {
                width: 60%;
            }

            @include below-screen-small {
                width: 95%;
            }

            @include below-screen-tiny {
                width: 95%;
            }
        }

        div.header {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            background-color: #1d2023;
            border-radius: 8px 8px 0 0;
            padding: 8px 12px 8px 12px;

            > button {
                background: none;
                border: none;
            }
        }

        div.content {
            width: 100%;
            height: 100%;
            padding: 12px;
        }
    }
}
