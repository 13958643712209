.row {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    gap: 5px;
}

.col {
}

.col-1 {  width: calc(1 / 12 * 100%); }
.col-2 { width: calc(2 / 12 * 100%); }
.col-3 { width: calc(3 / 12 * 100%); }
.col-4 { width: calc(4 / 12 * 100%); }
.col-5 { width: calc(5 / 12 * 100%); }
.col-6 { width: calc(6 / 12 * 100%); }
.col-7 { width: calc(7 / 12 * 100%); }
.col-8 { width: calc(8 / 12 * 100%); }
.col-9 { width: calc(9 / 12 * 100%); }
.col-10 { width: calc(10 / 12 * 100%); }
.col-11 { width: calc(11 / 12 * 100%); }
.col-12 { width: calc(12 / 12 * 100%); }

@include below-screen-small {
    .row {
        flex-direction: column;
    }
}