.muted {
    color: transparentize($color: white, $amount: 0.6);
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
small,
p {
    &.danger {
        color: hsla(349, 69%, 63%, 1);
    }

    &.success {
        color: #219653;
    }

    &.bold {
        font-weight: bold;
    }

    &.italic {
        font-style: italic;
    }

    &.underline {
        text-decoration: underline;
    }
}

.inform {
    font-style: italic;
    color: rgba(214, 200, 0, 0.459);
    font-size: 0.7rem;
}
