.m-0 {
    margin: 0;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.mr-0 {
    margin-right: 0;
}

.ml-0 {
    margin-left: 0;
}

.mt-1 {
    margin-top: 5px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mr-1 {
    margin-right: 5px;
}

.ml-1 {
    margin-left: 5px;
}

.mb-auto {
    margin-bottom: auto;
}

.mt-auto {
    margin-top: auto;
}