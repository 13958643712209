@import "../../../styles/mixins";

#subscriptionPage {
    padding: 20px 20px 200px 20px;

    h1,
    h2,
    h3,
    h4,
    h5,
    p.header {
        margin: 0px;
    }

    p.sub-header {
        margin: 4px 0px 0px 0px;
    }

    @include below-screen-xlarge {
        padding: 10px 10px 10px 10px;
    }
}

#licenseDetailsContainer {
    &.missing-license {
        border-radius: 8px;
        border: 1px dashed rgba(0, 105, 202, 0.6);
    }
}

#filtersContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 3px;

    > div {
        .checkbox-container,
        input {
            margin-right: 5px;
        }
    }
}

.subscriptionPage-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: repeat(auto-fill);
    gap: 24px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .card {
        display: flex;
        flex-direction: column;
        
        background-color: #1a1d20;
        padding: 14px;
        border-radius: 8px;
        width: 100%;

        .header {
            display: flex;
            flex-direction: row;
            gap: 14px;

            * {
                align-self: center;
            }

            > img {
                width: 21px;
                height: 21px;
                object-fit: contain;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            p {
                font-weight: 500;
                flex-grow: 1;
            }

            :nth-child(3) .dropdown {
                justify-content: right;
            }
        }
    }
}

#licensesStatusCardContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    div:nth-child(1) {
        border-right: 1px solid transparentize($color: #ffffff, $amount: 0.9);
    }
}

#nextPaymentCardContent {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(2, auto);
    margin-top: 24px;

    p {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

#subProgressCardContent {
    display: block;
    margin-top: 24px;

    > div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

#maintenancePurchaseCardContent {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    gap: 10px;
    align-items: center;
}

#subProgressCardContentBar {
    height: 5px;
    border-radius: 8px;
    background-color: transparentize($color: white, $amount: 0.8);
    overflow: hidden;

    > div:nth-child(1) {
        transition: width 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8) 0s;

        width: 0px;
        height: 100%;
        border-radius: 8px;
    }

    &.inactive {
        > div:nth-child(1) {
            background-color: #e25f77;
        }
    }

    &.active {
        > div:nth-child(1) {
            background-color: #57c967;
        }
    }
}

#subPageContainer {
    margin: 40px 0px 0px 0px;
    display: grid;
    grid-template-columns: 250px auto;
    grid-template-rows: auto minmax(auto, 500px);
    grid-template-areas:
        "tabs . "
        "outlet outlet";
    gap: 20px;

    @include below-screen-large {
        grid-template-areas:
            "tabs tabs"
            "outlet outlet";
    }
}
