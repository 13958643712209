*[data-tooltip] {
    cursor: help;
}

*[data-tooltip]::after {
    position: absolute;
    display: none;
    content: attr(data-tooltip);

    background-color: #0f1114;
    border-radius: 12px;
    border: 1px solid #1e2228;
    filter: drop-shadow(0 0 10px black);

    padding: 10px 20px;
    max-width: 250px;

    font-size: 13px;
    color: white;
}

*[data-tooltip]:hover::after {
    display: block;
}

sup[data-tooltip] {
    margin: 0px 1px;
}
