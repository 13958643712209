@import "../../../styles/mixins";

#modifySeatsDetails {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto auto;
    gap: 24px;

    width: calc((3 / 4 * 100%) - (24px / 2));

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 0;
    }

    .p-top-large {
        padding: 24px 0px 0px 0px;
    }

    .p-top-med {
        padding: 13px 0px 0px 0px;
    }

    .border-top {
        border-top: 1px solid #222528b2;
    }

    .icon {
        padding: 15px;
        background-color: #1a1d20;
        border-radius: 8px;
    }

    @include below-screen-large {
        grid-template-columns: 100%;
        width: 100%;
    }
}

#summaryContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
}
