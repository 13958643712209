@import "../styles/variables";

.checkbox-container {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 4px;
    border: 2px solid rgba($color: white, $alpha: 0.4);
    cursor: pointer;

    > img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: contain;
        user-select: none;
    }
}

.checkbox-container:hover {
    border: 2px solid rgba($color: white, $alpha: 0.6);
}

.checkbox-container:active {
    border: 2px solid rgba($color: white, $alpha: 0.3);
}

.checkbox-container {
    &.error {
        border: 2px solid rgba($color: $jfx-negative, $alpha: 1);
    }

    &.error:hover {
        border: 2px solid rgba($color: $jfx-negative-hover, $alpha: 0.8);
    }

    &.error:active {
        border: 2px solid rgba($color: $jfx-negative-pressed, $alpha: 0.6);
    }
}

.checkbox-container.checked {
    background-color: $jfx-primary;
    border: 2px solid $jfx-primary;
}

.checkbox-container.checked:hover {
    background-color: $jfx-primary-hover;
    border: 2px solid $jfx-primary-hover;
}

.checkbox-container.checked:active {
    background-color: $jfx-primary-pressed;
    border: 2px solid $jfx-primary-pressed;
}

.checkbox-container > input {
    display: none;
}

.checkbox-container .checkmark > svg {
    path {
        stroke: $jfx-bg-secondary;
    }
}
