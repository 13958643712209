@import "../styles/variables";
@import "../styles/mixins";

#topbar {
    position: sticky;
    top: 0;

    display: flex;
    flex-direction: row;

    justify-content: space-between;

    height: $top-bar-height;
    background-color: $jfx-bg-primary;

    padding-top: 15px;
    padding-bottom: 15px;

    div:nth-child(2) {
        display: flex;
        flex-direction: row;
    }
}

#topbar .search {
    width: 300px;
}

#expandButton {
    display: none; // Hide on desktops

    height: 20px;
    margin-left: 15px;

    @include below-screen-large {
        display: block;
    }
}
