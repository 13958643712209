.selector {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%; // tbr
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffffff33;
}

.selector-item {
    flex-grow: 1;
    padding: 7px 15px;
    border: none;
    border-radius: 8px;
    background-color: transparent;

    &:not(.disabled) {
        cursor: pointer;
    }

    &:hover:disabled {
        cursor: not-allowed;
    }

    &.active {
        background-color: #254360;
    }

    &:hover:not(.active):not(:disabled) {
        background-color: #ffffff0a;
    }
}
