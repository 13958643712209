@import "../styles/variables";
@import "../styles/mixins";

@mixin button($btn-bg-color, $btn-border-color, $btn-text-color) {
    background-color: $btn-bg-color;
    border: 2px solid $btn-border-color;
    color: $btn-text-color;

    &:hover {
        background-color: lighten($color: $btn-bg-color, $amount: 5);
        border: 2px solid lighten($color: $btn-border-color, $amount: 5);
    }

    &:active {
        background-color: darken($color: $btn-bg-color, $amount: 5);
        border: 2px solid darken($color: $btn-border-color, $amount: 5);
        transform: translateY(1px);
    }
}

div.btn,
button.btn,
a.btn {
    cursor: pointer;
    padding: 10px 16px 10px 16px;

    font-size: 0.85rem;
    border-radius: 48px;
    font-weight: bold;
    text-align: center;
    user-select: none;
    text-decoration: none;

    border: 2px solid transparent; // placeholder transparent border

    @include below-screen-large {
        width: 100%;

        & + button {
            margin-top: 5px;
        }
    }

    &.primary {
        @include button($jfx-primary, transparent, white);
    }

    &.secondary {
        @include button(#ffffff0f, #ffffff0f, white);
    }

    &.tertiary {
        @include button(#1a1d20, hsla(0, 0%, 100%, 0.16), white);
    }

    &.danger {
        @include button(#e25f771a, #e25f77, white);
    }

    &.success {
        @include button(#219653, #219653, white);
    }

    &.blackfriday {
        @include button($jfx-blackfriday, transparent, white);
    }

    &:disabled {
        filter: brightness(0.7) grayscale(1);

        &:active {
            transform: none !important;
        }
    }
}

button > img {
    vertical-align: middle;
    user-select: none;
}

button > .icon {
    user-select: none;
    vertical-align: middle;
    margin-right: 7px;
}
