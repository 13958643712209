/**
    These breakpoints provide a DESKTOP-FIRST approach!
**/

// Margins
.m-1 { margin: 10px; }
.m-1-top { margin-top: 10px; }
.m-1-bottom { margin-bottom: 10px; }
.m-1-left { margin-left: 10px; }
.m-1-right { margin-right: 10px; }

// Paddings
.p-1 { padding: 10px; }
.p-1-top { padding-top: 10px; }
.p-1-bottom { padding-bottom: 10px; }
.p-1-left { padding-left: 10px; }
.p-1-right { padding-right: 10px; }

.p-2 { padding: 20px; }
.p-2-top { padding-top: 20px; }
.p-2-bottom { padding-bottom: 20px; }
.p-2-left { padding-left: 20px; }
.p-2-right { padding-right: 20px; }

// Sizes
.w-100 { width: 100%; }
.w-75 { width: 75%; }
.w-50 { width: 50%; }
.w-25 { width: 25%; }



/// SCREEN SIZE BREAK POINTS

// Tiny

@mixin below-screen-tiny {
    @media screen and (max-width: 480px) {
        @content;
    }
}

@mixin above-screen-tiny {
    @media screen and (min-width: 481px) {
        @content;
    }
}

// Small

@mixin below-screen-small {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin above-screen-small {
    @media screen and (min-width: 769px) {
        @content;
    }
}

// Large

@mixin below-screen-large {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin above-screen-xlarge {
    @media screen and (min-width: 1025px) {
        @content;
    }
}

// X-Large

@mixin below-screen-xlarge {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

// XX-Large

@mixin below-screen-xxlarge {
    @media screen and (min-width: 1200px) {
        @content;
    }
}
