@import "../../../styles/mixins";

#subscriptionsPage {
    margin: 20px;
}

#subscriptionCardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, 330px);
    gap: 20px;

    @include below-screen-small {
        grid-template-columns: 100%;
    }
}
